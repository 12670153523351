import React from "react";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DoneIcon from "@material-ui/icons/Done";
import SettingsIcon from "@material-ui/icons/Settings";

export const products = [
  {
    name: "1st Commit",
    action: "Buy",
    priceline: "$500 one time fee",
    tagline: "Get your application up and running, ready for engineers.",
    icon: <DoneIcon fontSize="large" color="secondary" />,
    href: "#",
    features: [
      {
        name: "Bazel Monorepo",
        description: "Reproducible builds."
      },
      {
        name: "Github Organization",
        description: "With code owners."
      },
      {
        name: "AWS Organization",
        description: "Multi-account with SSO."
      },
      {
        name: "Everything as Code",
        description: "All infrastructure defined via code."
      },
      {
        name: "Application Templates",
        description:
          "Fully-functional modern API, static site, app, and analytics templates."
      },
      {
        name: "Slack",
        description: "Integrated with your environment."
      }
    ]
  },
  {
    name: "Nth Commit",
    action: "Subscribe",
    priceline: "$399/month, billed annually",
    tagline: "Code updates, team supported, onboarding time reduced.",
    icon: <DoneAllIcon fontSize="large" color="secondary" />,
    href: "#",
    features: [
      {
        name: "1st commit!",
        description: "A solid baseline!"
      },
      {
        name: "Code Updates via PR",
        description: "Latest features, security and bug fixes."
      },
      {
        name: "Virtual Development",
        description: "A zero-configuration secured environment in the cloud."
      },
      {
        name: "Advanced Infosec",
        description: "Risk & Compliance controls and policies out-of-the-box."
      },
      {
        name: "Business Hours Slack",
        description: "Real-time interaction with our team to accelerate yours."
      }
    ]
  },
  {
    name: "Custom",
    action: "Contact Us",
    priceline: "It's complicated...",
    tagline: "That's why we've built initcommit! Let's talk!",
    icon: <SettingsIcon fontSize="large" color="secondary" />,
    href: "mailto:contact@pnwcode.com",
    features: [
      {
        name: "Cloud Migration",
        description:
          "Migrating to the cloud or from another? Let us help plan and execute!"
      },
      {
        name: "Digital Transformation",
        description: "We'll augment and train up your team."
      },
      {
        name: "Bazelfy",
        description: "Your app, building with bazel."
      },
      {
        name: "Certifications",
        description:
          "PCI/DSS, HIPPA, SOC II, Federal Reserve Audit? We can help!"
      },
      {
        name: "24/7 Support",
        description: "Top level cloud experts, ready to help!"
      }
    ]
  }
];
