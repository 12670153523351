export const zeroToSixty = [
  {
    name: "Monorepo",
    text:
      'Your code, in one place. Applications, infrastructure and even "The Website" living together in harmony.'
  },
  {
    name: "Cloud Infrastructure",
    text: "All of your cloud, defined in code, in your monorepo."
  },
  {
    name: "Continuous Integration & Deployment",
    text: "Build, test, deploy from first commit on."
  },
  {
    name: "Infosec, Risk, & Compliance Controls",
    text:
      "Proven processes and controls which will pass SOCII, PCI or a Federal Reserve Audit."
  },
  {
    name: "Third-Party Integrations",
    text:
      "Leverage an entire marketplace of partners to achieve your business goals."
  },
  {
    name: "Modern Technology Stacks",
    text:
      "Serverless, modern languages, fast builds, all means your customers get your product faster."
  },
  {
    name: "Production Ready Templates",
    text:
      "Select, customize and deploy applications and services to power up your product."
  },
  {
    name: "Focus on Managed Services",
    text:
      "Your product is what makes you special, let us handle the risks with managed services from AWS."
  },
  {
    name: "Secure",
    text:
      "With TLS, OAuth, IAM, KMS and tokenization we make sure your sensitive data stays protected."
  }
];
