import React, { useState } from "react";
import "typeface-montserrat";
import AddIcon from "@material-ui/icons/Add";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Helmet from "react-helmet";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import logo from "../images/logo.svg";
import rocket from "../images/rocket.svg";
import server from "../images/server.svg";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { products } from "../data/products";
import { withStyles } from "@material-ui/core/styles";
import { zeroToSixty } from "../data/zeroToSixty";

const bootRef = React.createRef();

const theme = createMuiTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif"
  }
});

const styles = theme => ({});

const HomePage = ({ classes }) => {
  const [product, setProduct] = useState(0);
  const productChange = (event, value) => setProduct(value);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>initcommit</title>
        <meta name="description" content="initcommit beta" />
      </Helmet>
      <CssBaseline />
      <Container fixed maxWidth="md">
        <Box mb={3} mt={3}>
          <Grid container>
            <Grid item xs={7}>
              <img alt="initcommit logo" src={logo} width="225" />
            </Grid>
            <Grid item xs={5}>
              <Grid container justify="flex-end">
                <Hidden xsDown>
                  <Button
                    variant="contained"
                    color="secondary"
                    endIcon={<HelpOutlineOutlinedIcon />}
                    href="mailto:contact@pnwcode.com"
                  >
                    Customer Support
                  </Button>
                </Hidden>
                <Hidden smUp>
                  <IconButton
                    color="secondary"
                    href="mailto:contact@pnwcode.com"
                  >
                    <HelpOutlineOutlinedIcon />
                  </IconButton>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box p={{ xs: 1, sm: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7} container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h5">Everything as Code</Typography>
                <Typography variant="h3" gutterBottom>
                  <Box fontWeight="900">IT IS TECH ACCEL</Box>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Our product is code, and we’re obsessed. From top to bottom,
                  the code we sell is yours. You control it all.
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    bootRef.current.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "center"
                    })
                  }
                >
                  Boot Your Technical Stack
                </Button>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item sm={5}>
                <Grid container justify="flex-end">
                  <img alt="rocket" src={rocket} width="300" />
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
        </Box>
        <Box mt={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardActionArea>
                  <CardContent>
                    <Typography gutterBottom variant="h4">
                      Initialize
                    </Typography>
                    <pre>$ git init --template=...</pre>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Lizards are a widespread group of squamate reptiles, with
                      over 6,000 species, ranging across all continents except
                      Antarctica
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary">
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardActionArea>
                  <CardContent>
                    <Typography gutterBottom variant="h4">
                      Bootstrap
                    </Typography>
                    <pre>$ bazel run bootstrap</pre>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Lizards are a widespread group of squamate reptiles, with
                      over 6,000 species, ranging across all continents except
                      Antarctica
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary">
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardActionArea>
                  <CardContent>
                    <Typography gutterBottom variant="h4">
                      Deploy
                    </Typography>
                    <pre>$ bazel build deploy</pre>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Lizards are a widespread group of squamate reptiles, with
                      over 6,000 species, ranging across all continents except
                      Antarctica
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary">
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Box mt={10} mb={10}>
            <Container maxWidth="xs" disableGutters>
              <Typography variant="h4" gutterBottom>
                <Box fontWeight="900">Zero to 60 in minutes</Box>
              </Typography>
              <Typography variant="body2" gutterBottom>
                Our product is code, and we’re obsessed. From top to bottom, the
                code we sell is yours. You control it all.
              </Typography>
            </Container>
          </Box>
          <Grid container spacing={2}>
            {zeroToSixty.map((item, i) => (
              <Grid key={i} item xs={12} sm={6} md={4} container>
                <Grid item xs={4}>
                  <img
                    alt="rocket"
                    src={rocket}
                    width="50"
                    className={classes.centered}
                  />
                </Grid>
                <Grid item xs={8} container direction="column">
                  <Grid item>
                    <Typography variant="h5">{item.name}</Typography>
                    <Typography variant="body2" gutterBottom>
                      {item.text}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box>
          <Box mt={10} mb={10}>
            <Container maxWidth="xs" disableGutters>
              <Typography variant="h4" gutterBottom>
                <Box fontWeight="900">The Process</Box>
              </Typography>
              <Typography variant="body2" gutterBottom>
                Our product is code, and we’re obsessed. From top to bottom, the
                code we sell is yours. You control it all.
              </Typography>
            </Container>
          </Box>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Container maxWidth="sm">
                <Stepper orientation="vertical">
                  {[
                    "Apply",
                    "Grant repository access",
                    "Merge initcommit bot PR",
                    "Push to cloud"
                  ].map((label, i) => (
                    <Step key={label} active>
                      <StepLabel>{label}</StepLabel>
                      <StepContent>
                        <Typography variant="body2" gutterBottom>
                          Our product is code, and we’re obsessed. From top to
                          bottom, the code we sell is yours. You control it all.
                        </Typography>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Container>
            </Grid>
            <Hidden smDown>
              <Grid item sm={5}>
                <img alt="server" src={server} width="300" />
              </Grid>
            </Hidden>
          </Grid>
        </Box>
        <Box>
          <Box mt={10} mb={10} align="center" ref={bootRef}>
            <Container maxWidth="md">
              <Typography variant="h3">
                <Box fontWeight="900">Boot Your Technical Stack</Box>
              </Typography>
            </Container>
          </Box>
          <Box mt={5}>
            <Tabs
              value={product}
              onChange={productChange}
              centered
              variant="fullWidth"
            >
              {products.map(({ name, icon }, i) => (
                <Tab key={i} label={name} value={i} icon={icon} />
              ))}
            </Tabs>
            <Grid container>
              {products.map(
                ({ name, priceline, tagline, action, href, features }, i) => (
                  <Hidden key={i} smDown={i !== product}>
                    <Grid item xs={12} md={4}>
                      <Card value={name} index={i}>
                        <CardHeader title={priceline} subheader={tagline} />
                        <CardContent>
                          <List dense>
                            {features.map(({ name, description }, i) => (
                              <ListItem key={i}>
                                <ListItemIcon>
                                  <AddIcon fontSize="small" color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                  primary={name}
                                  secondary={description}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </CardContent>
                        <CardActions>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            href={href}
                          >
                            {action}
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  </Hidden>
                )
              )}
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default withStyles(styles)(HomePage);
